@import "../_base/variables";
@import "bootstrap/scss/_functions";
@import "bootstrap/scss/_variables";
@import "bootstrap/scss/mixins/_breakpoints";

.maintenance-page {
    background: $primary;
    position: fixed;
    width: 100%;
    height: 100%;
    display: none;

    &__box {
        display: block;
        width: 600px;
        margin: 10% auto;
        max-width: 90%;
        background: $white;
        padding: 50px;
        border-radius: 10px;

        h1 {
            font-size: 2em;
            font-weight: 600;
            color: $primary;
        }

        p {
            margin: 15px 0;
        }
    }
}

.new-release {
    background: #f3f3f3;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px 0;

    @include media-breakpoint-down(lg) {
        padding: 60px 0;
    }

    .container {
        display: flex;
        align-items: center;
        justify-content: center;

        @include media-breakpoint-down(lg) {
            flex-direction: column;
        }
    }

    &__header {
        padding-right: 20px;

        @include media-breakpoint-down(lg) {
            padding-right: 0;
        }

        h1 {
            color: $black-stone;
            text-align: center;

            @include font-size($font-xx-big);

            font-weight: $font-weight--bold;
        }

        > div {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            margin: 15px 0;

            span {
                margin-right: 10px;
                color: $primary;
                border: 1px solid lightgray;
                background: white;
                padding: 10px 20px;
                border-radius: 10px;
                min-width: 130px;
                text-align: center;
                line-height: 1.3;
                margin-bottom: 10px;

                @include font-size($font-large);

                font-weight: $font-weight--bold;

                @include media-breakpoint-down(lg) {
                    @include font-size($font-big);
                }

                &:nth-of-type(1) {
                    flex: 1 0 100%;
                }

                &:nth-of-type(2) {
                    flex: 0 31%;
                }

                &:nth-of-type(3) {
                    flex: 0 31%;
                }

                &:nth-of-type(4) {
                    flex: 0 31%;
                }
            }
        }

        p {
            color: $black-oak;
            text-align: center;
            margin-top: 20px;

            @include font-size($font-medium);

            font-weight: $font-weight--regular;
        }
    }

    &__content {
        padding-left: 20px;

        @include media-breakpoint-down(lg) {
            padding-left: 0;
        }

        iframe {
            width: 500px;
            min-height: 430px;

            @include media-breakpoint-down(md) {
                width: 350px;
                min-height: 660px;
            }
        }
    }
}
